<template>
  <div class="setting-item s-database">
    <esmp-loader v-if="isLoading" fix />
    <div class="s-database__block">
      <esmp-select
        v-model="database"
        placeholder="Выбор БД"
        class="s-database__db-list"
      >
        <esmp-select-option
          v-for="(item, index) in databases"
          :value="item"
          :key="`database-${index}`"
        >
          {{ item.name }}
        </esmp-select-option>
      </esmp-select>
      <esmp-button
        @click="isAddDatabase = true"
        icon="plus"
        view="outline"
      />
    </div>
    <div class="s-database__field">
      <esmp-button
        view="function"
        @click="isEditConnections = true"
      >
        Редактировать список подключений к БД
      </esmp-button>
    </div>
    <div class="s-database__field" v-if="database">
      <esmp-select
        v-model="table"
        filterable
        placeholder="Выбор таблицы"
      >
        <esmp-select-option
          v-for="item in tables"
          :value="item"
          :key="item.name"
        >
          {{ item.name }}
        </esmp-select-option>
      </esmp-select>
    </div>
    <div class="s-database__field" v-if="table">
      <div class="s-database__table-header">
        <h4>{{ selectedColumns.length ? 'Выбранные столбцы' : 'Столбцы не выбраны' }}</h4>
        <esmp-button
          @click="showAddColumnsModal"
          icon="plus"
          view="outline"
          size="small"
        />
      </div>
      <div class="s-database__columns-list" v-if="columns.length">
        <draggable
          :list="selectedColumns"
          group="columns"
          @change="changeSelectedColumns"
        >
          <div
            class="s-database__column"
            v-for="(column, index) in selectedColumns"
            :key="`column-${index}`"
          >
            <div class="s-database__column-name">
              {{ column.name }}
            </div>
            <div class="s-database__column-label">
              {{ column.label ? column.label : '-' }}
            </div>
            <div class="s-database__actions">
              <esmp-button
                icon="edit"
                view="function"
                @click="showAddColumnsModal"
              />
              <esmp-button
                icon="trash"
                view="function"
                @click="removeSelectedColumn(index, true)"
              />
            </div>
          </div>
        </draggable>
      </div>
      <div v-else>
        Список колонок пуст
      </div>
    </div>
    <modal-set-connection
      v-model="isAddDatabase"
      :current-database="currentDatabase"
      @get-databases="getDatabases"
    />
    <modal-edit-connections
      v-model="isEditConnections"
      :databases="databases"
      @edit-connection="editConnection"
      @remove-connection="removeConnection"
    />
    <modal-remove-connection
      v-model="isRemoveConnection"
      @approve-remove-connection="approveRemoveConnection"
    />
    <esmp-modal
      v-model="isAddColumns"
      title="Добавление столбца"
      class="add-columns-modal"
    >
      <validation-observer
        ref="addColumnsForm"
        tag="div"
      >
        <esmp-select
          ref="columnsList"
          v-model="selectedColumnsNames"
          multiple
          placeholder="Выбор столбцов"
          class="add-columns-modal__column-list"
          @on-select="setSelectedColumns"
        >
          <esmp-select-option
            v-for="(item, index) in columns"
            :value="item.value"
            :label="item.name"
            :key="`column-${index}`"
          >
            {{ item.name }}
          </esmp-select-option>
        </esmp-select>
        <div class="add-columns-modal__list">
          <h4>{{ modalSelectedColumns.length ? 'Выбранные столбцы' : 'Столбцы не выбраны' }}</h4>
          <div
            v-for="(column, index) in modalSelectedColumns"
            :key="`column-${index}`"
            class="add-columns-modal__item"
          >
            <div class="add-columns-modal__item-name">
              {{ column.name }}
            </div>
            <div class="add-columns-modal__item-settings">
              <esmp-input
                v-model="modalSelectedColumns[index].label"
                label="Название"
                class="add-columns-modal__item-label"
              />
              <validation-provider
                rules="required"
                v-slot="v"
                name="Тип"
              >
                <esmp-select
                  v-model="modalSelectedColumns[index].visualType"
                  placeholder="Тип"
                  :class="{'add-columns-modal__item-error': v.errors.length}"
                >
                  <esmp-select-option
                    v-for="type in DATABASE_COLUMN_VISUAL_TYPE"
                    :value="type.techName"
                    :key="type.techName"
                  >
                    {{ type.name }}
                  </esmp-select-option>
                </esmp-select>
              </validation-provider>
              <esmp-select
                v-model="modalSelectedColumns[index].sortType"
                placeholder="Сортировка"
              >
                <esmp-select-option
                  v-for="sort in DATABASE_COLUMN_SORT_TYPE"
                  :value="sort.techName"
                  :key="sort.techName"
                >
                  {{ sort.name }}
                </esmp-select-option>
              </esmp-select>
              <esmp-input
                v-model="modalSelectedColumns[index].filterValues"
                class="add-columns-modal__item-filter"
                label="Фильтр"
                hint="Перечислять через запятую"
              />
              <esmp-button
                icon="trash"
                view="function"
                @click="removeSelectedColumn(index)"
              />
              <div class="add-columns-modal__item-is-required">
                <esmp-checkbox v-model="modalSelectedColumns[index].required">
                  Обязательное заполнение
                </esmp-checkbox>
              </div>
            </div>
          </div>
        </div>
      </validation-observer>
      <template #footer>
        <esmp-button @click="addColumns">
          Добавить
        </esmp-button>
        <esmp-button view="outline" @click="isAddColumns = false">
          Отменить
        </esmp-button>
      </template>
    </esmp-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapActions } from 'vuex';
import {
  DATABASE_COLUMN_VISUAL_TYPE,
  DATABASE_COLUMN_SORT_TYPE,
  DEFAULT_DATABASE_OBJECT,
  DEFAULT_VALUE_OBJECT,
} from '@/constants/database';
import cloneDeep from 'lodash/cloneDeep';
import ModalSetConnection from './ModalSetConnection.vue';
import ModalEditConnections from './ModalEditConnections.vue';
import ModalRemoveConnection from './ModalRemoveConnection.vue';

export default {
  name: 'SDatabase',
  components: {
    draggable,
    ModalEditConnections,
    ModalSetConnection,
    ModalRemoveConnection,
  },
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  data() {
    return {
      DATABASE_COLUMN_VISUAL_TYPE,
      DATABASE_COLUMN_SORT_TYPE,
      isLoading: false,
      isAddDatabase: false,
      isEditConnections: false,
      isRemoveConnection: false,
      isAddColumns: false,
      currentDatabase: { ...DEFAULT_DATABASE_OBJECT },
      database: null,
      databases: null,
      table: null,
      tables: [],
      columns: [],
      selectedColumns: [],
      selectedColumnsNames: [],
      modalSelectedColumns: [],
      valueObject: this.setting.value || DEFAULT_VALUE_OBJECT,
      isInit: false,
    };
  },
  watch: {
    database(val) {
      if (this.isInit) {
        if (val) {
          this.setValue(val.databaseId, 'databaseId');
          this.$nextTick(() => {
            this.getTablesList();
          });
        }
        this.table = null;
        this.selectedColumns = [];
      }
    },
    table(val) {
      if (this.isInit) {
        if (val) this.getTableColumns();
        this.selectedColumns = [];
        this.selectedColumnsNames = [];
        this.modalSelectedColumns = [];
      }
    },
    isAddDatabase(val) {
      if (!val) {
        this.resetDatabase();
      }
    },
    isEditConnections(val) {
      if (!val) {
        this.resetDatabase();
      }
    },
    isRemoveConnection(val) {
      if (!val) {
        this.resetDatabase();
      }
    },
    selectedColumns(val) {
      this.setValue(val, 'columns');
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    async init() {
      await this.getDatabases();

      if (this.setting.value) {
        const { databaseId } = this.setting.value;
        this.database = this.databases.find((db) => db.databaseId === databaseId);
        await this.getTablesList();
        this.table = this.tables.find((table) => table.name === this.setting.value?.columns[0]?.tableName);
        if (this.table) {
          await this.getTableColumns();
          this.selectedColumns = [...this.setting.value.columns].map((column) => ({
            ...column,
            filterValues: column.filterValues?.join(','),
          }));
          this.selectedColumnsNames = [...this.setting.value.columns].map((column) => ({
            ...column,
            value: column.name,
            label: column.label,
            name: column.name,
            filterValues: column.filterValues?.join(','),
          }));
          this.modalSelectedColumns = [...this.selectedColumnsNames];
        }
        this.$nextTick(() => {
          this.isInit = true;
        });
      } else {
        this.isInit = true;
      }
    },
    async setValue(value, name) {
      let localValue = value;
      if (name === 'columns') {
        localValue = [...value];
        // eslint-disable-next-line no-param-reassign
        localValue.forEach((column) => delete column.value);
      }
      this.$set(this.valueObject, name, localValue);
      this.setting.value = cloneDeep(this.valueObject);
    },
    getDatabases() {
      this.isLoading = true;
      return this.$API.database.getDatabases()
        .then(({ data }) => {
          this.databases = data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    editConnection(database) {
      this.isAddDatabase = true;
      this.currentDatabase = database;
    },
    removeConnection(database) {
      this.currentDatabase = database;
      this.isRemoveConnection = true;
    },
    approveRemoveConnection() {
      return this.$API.database.removeDatabaseConnection(
        this.currentDatabase.databaseId,
        this.currentDatabase.url,
      )
        .then(() => {
          this.$EsmpNotify.$show('Подключение к БД успешно удалено', 'success');
          this.isRemoveConnection = false;
          // eslint-disable-next-line max-len
          const isExistDatabase = this.databases.findIndex((db) => db.databaseId === this.currentDatabase.databaseId);
          this.databases.splice(isExistDatabase, 1);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getTablesList() {
      this.isLoading = true;
      return this.$API.database.getTablesList(this.database.databaseId)
        .then(({ data }) => {
          this.tables = data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getTableColumns() {
      this.isLoading = true;
      return this.$API.database.getTableColumns(this.database.databaseId, this.table.name)
        .then(({ data }) => {
          this.columns = data.map((column) => ({
            ...column,
            value: column.name,
            label: column.label,
            name: column.name,
            filterValues: column.filterValues?.join(','),
          }));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    addColumns() {
      this.$refs.addColumnsForm.validate()
        .then((valid) => {
          if (valid) {
            this.isAddColumns = false;
            this.$nextTick(() => {
              this.selectedColumns = [...this.modalSelectedColumns.map((col) => ({
                ...col,
                filterValues: col.filterValues?.length ? col.filterValues.split(',') : [],
              }))];
              this.setValue(this.selectedColumns, 'columns');
              this.columns = this.columns.map((column) => ({
                ...column,
                value: column.name,
                label: column.label,
                name: column.name,
                filterValues: column.filterValues?.join(','),
              }));
            });
          } else {
            this.$EsmpNotify.$show('Поле Тип является обязательным', 'error');
          }
        });
    },
    removeSelectedColumn(index, isComplited) {
      const deletedColumn = this.modalSelectedColumns[index];
      this.selectedColumnsNames = this.selectedColumnsNames.filter((col) => col !== deletedColumn.name);
      this.modalSelectedColumns.splice(index, 1);
      if (isComplited) {
        this.selectedColumns.splice(index, 1);
        this.changeSelectedColumns();
      }
    },
    showAddColumnsModal() {
      this.selectedColumnsNames = [...this.selectedColumns];
      this.$refs.columnsList.values = [...this.selectedColumnsNames].map((column) => ({
        ...column,
        value: column.name,
        label: column.label,
        name: column.name,
      }));
      this.isAddColumns = true;
    },
    setSelectedColumns() {
      this.$nextTick(() => {
        this.selectedColumnsNames.forEach((colName) => {
          const isExist = this.modalSelectedColumns.find((col) => col.name === colName);
          if (!isExist) {
            const newColumn = this.columns.find((col) => col.name === colName);
            this.modalSelectedColumns.push(newColumn);
          }
        });
      });
    },
    resetDatabase() {
      this.currentDatabase = { ...DEFAULT_DATABASE_OBJECT };
    },
    changeSelectedColumns() {
      this.selectedColumns = this.selectedColumns.map((col) => ({
        ...col,
        filterValues: col.filterValues?.length ? col.filterValues.split(',') : [],
      }));
      this.setValue(this.selectedColumns, 'columns');
    },
  },
  created() {
    this.init();
  },
};
</script>
<style lang="scss">
.s-database {
  position: relative;

  &__block {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__db-list {
    margin-right: 20px;
  }

  &__field {
    margin-bottom: 16px;
  }

  &__table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__column {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-grayscale-05;
    padding: 5px;
    cursor: move;
  }

  &__column-name {
    flex-shrink: 0;
    width: 40%;
  }

  &__column-label {
    flex-shrink: 0;
    width: 40%;
  }

  &__actions {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
}

.add-columns-modal {
  &__column-list {
    margin-bottom: 16px;
  }
  &__item {
    margin-bottom: 16px;

    .esmp-select {
      width: 200px;
    }

    .esmp-button,
    .esmp-select {
      flex-shrink: 0;
    }
  }

  &__item-name {
    flex-grow: 1;
    margin-bottom: 16px;
  }

  &__item-settings {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__item-error {
    border: 1px solid $color-red;
    border-radius: $base-border-radius;
  }

  &__item-label {
    width: 100%;
    flex-shrink: 0;
  }

  &__item-filter {
    flex-grow: 1;
  }

  &__item-is-required {
    width: 100%;
    flex-shrink: 0;
  }
}
</style>
